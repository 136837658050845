



jQuery(document).ready(function($){

	screenSmall();
	smoothScroll();
	accordion();
	menu();

	$(window).resize(function(){
		menu();
	});

	// *******************************************************************

	function menu() {

		var div = $(".masthead").height(),
			win = $(window).height();

		if (div > win ) {
			$(".masthead").addClass('static');
		} else {
			$(".masthead").removeClass('static');
		}

	}

	// *******************************************************************

	function accordion() {

		var allPanels = jQuery('.tree-accordion > dd').hide();

		jQuery('.tree-accordion > dt > a').click(function() {
			$target = jQuery(this).parent().next();

			if(!$target.hasClass('active')) {
				allPanels.removeClass('active').slideUp();
				$target.addClass('active').slideDown(100);
			}

			return false;
		});

		jQuery('.tree-accordion-empty').prev().hide();

	}

// *******************************************************************

function screenSmall() {

	$('.masthead-menu').on('click', function() {

		$('#master').toggleClass('menu-open');

	});

}

// *******************************************************************

function smoothScroll() {

	$('a[href*="#"]:not([href="#"])').click(function() {
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
			if (target.length) {
				$('html, body').animate({
					scrollTop: target.offset().top
				}, 750);
				return false;
			}
		}
	});

}

// *******************************************************************

});